.a {
  /* height: 100vh; */
  display: flex;
  align-items: center;
}

.a-left {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1 1;
  padding-right: 32px;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.a-title {
  font-weight: 400;
}

.a-sub {
  margin: 20px 0px;
}

.a-desc{
    font-weight: 300;
}

.a-award{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-award-texts{
    width: 70%;
}

.a-award-title{
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

@media screen and (max-width:480px) {
    .a{
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg, .a-award {
      display: none;
    }

    .a-right{
      padding: 20px;
    }
}
.i {
  display: flex;
  height: 100vh;
}

.i-left {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right {
  flex: 1 1;
  position: relative;
}

.i-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i-intro {
  font-size: 30px;
  font-weight: 300;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #59b256;
  display: flex;
  align-items: center;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.i-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.i-bg {
  -webkit-clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
          clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-color: #59b256;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 480px) {
  .i {
    flex-direction: column;
  }

  .i-left-wrapper {
  
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .i-desc{
    display: none;
  }
  
}

.sosmed-wrapper {
  margin-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.sosmed-wrapper img {
  height: 60px;
  width: 60px;
}

.pl {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-texts {
  width: 65%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
}

.pl-desc {
  margin: 20px 0px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 10px;
  }

  .pl-title {
    font-size: 30px;
    font-weight: 600;
  }

  .pl-texts {
    width: 100%;
  }

  .pl-desc {
    /* display: none; */
  }
}

.p {
  width: 30%;
  height: 40vh;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
}

.p-img {
  width: 100%;
  transition: all 10s ease;
}

.p:hover .p-img {
  transform: translateY(-100%);
}

@media screen and (max-width: 480px) {
  .p {
    width: 40%;
    height: 20vh;
  }
}

.t{
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border: 1pz solid #999;
    background-color: white;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.t-icon{
    width: 15px;
    height: 15px;
}

.t-button{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #999;
    position: absolute;
    cursor: pointer;
}
