.sosmed-wrapper {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.sosmed-wrapper img {
  height: 60px;
  width: 60px;
}
